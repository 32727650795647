document.addEventListener("DOMContentLoaded", function(){

	//ich halte die lösung für mehr als fragwürdig und verbesserungswürdig ... ich brauch doch kein reload wenn ich da unten auf width < 992 was mache kann ich doch auch was auf =>922 machen ... und zwar das genaue gegenteil ohne reloaden zu müssen um die änderungen rückgängig zu machen

	//hier die width merken
	//var windowWidth = window.innerWidth
	//das resize event wird dummerweise auf mobilen geräten auch beim scrollen ausgelöst
	/*window.addEventListener("resize", function() {
			//deshalb vergeleichen ob sich die width geändert hat oder nicht ... wenn nicht dann braucht es auch kein reload
	        if (window.innerWidth != windowWidth) {
				//"use strict"; window.location.reload();
				windowWidth = window.innerWidth
			}
	});*/

    //was machtz das hier??
    /////// Prevent closing from click inside dropdown
    document.querySelectorAll('.dropdown-menu').forEach(function(element){
        element.addEventListener('click', function (e) {
            e.stopPropagation();
        });
    });




    // make it as accordion for smaller screens
    //der teil muss geladen werden um damit im kleinem fenster modus das multilevel funktioniert

    function fenstersizecheck() {
        // close all inner dropdowns when parent is closed
        //er geht durch das ganze dokument und schaut was .navbar und .dropdown für klassen da sind
        //danach geht erdurch jede klasse und fürht die funktion aus
        //wenn ein dropdown menü ausgeblendet wird dann werden dann alle ausgeblendet
        //zurücksetzten des dropdown menüs
        //aber nur die submenüs
        //würde sagen ist dazu da damit alle submenüs geschlossen werden wenn man den unterpunkt zu macht
        //ist dazu da damit alle menüteile zu gehen wenn man das ganze menü zu macht
        document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
            //es wird bei jeder klasse das event hidden.bs.dropdown ausgeführt

            everydropdown.addEventListener('hidden.bs.dropdown', function () {
                // after dropdown is hidden, then find all submenus
                this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
                    // hide every submenu as well
                    //wird aktiviert wenn das submenü einmal geschlossen wird und ann wieder geöffnet
                    if (window.innerWidth <= 1200) {
                        everysubmenu.style.display = 'none';
                    }
                });
            })

            everydropdown.addEventListener('show.bs.dropdown', function () {
                // after dropdown is hidden, then find all submenus
                this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
                    // hide every submenu as well
                    //wird aktiviert wenn das submenü einmal geschlossen wird und ann wieder geöffnet
                    if (window.innerWidth > 1200) {
                        everysubmenu.removeAttribute("style");
                    }
                });
            })

        });

        // er wählt alle <a> und dropdown-menü klassen aus
        document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
            //es bekommen alle ein onclick event
            element.addEventListener('click', function (e) {
                if(window.innerWidth<=1200) {
                    //nimmt hier das nächste element
                    let nextEl = this.nextElementSibling;
                    //und schaut ob submenü drinsteht in der klasse
                    if (nextEl && nextEl.classList.contains('submenu')) {
                        // prevent opening link if link needs to open dropdown
                        e.preventDefault();
                        //und öffnet es dann nicht
                        if (nextEl.style.display == 'block') {
                            //wird erst beim zweiten anklicken ajktiviert
                            //also wahrscheinlich wenn die submenüs zu gehen bzw eins schließt
                            nextEl.style.display = 'none';
                        } else {
                            //wird immer aktiviert bei jeden klick
                            //also beim öffnen eines submenüs
                            nextEl.style.display = 'block';

                            nextEl.addEventListener('show.bs.dropdown', function () {
                                nextEl.style.display = 'none';
                            });

                            if(window.innerWidth<=1200){
                                var windowklein = true;
                            }else{
                                var windowklein = false;
                            }

                            window.addEventListener("resize", function() {
                                if(window.innerWidth<=1200 && windowklein==false){

                                    nextEl.style.display = 'none';
                                    windowklein = true;
                                    $(".dropdown-menu").removeClass("show");


                                }

                                if(window.innerWidth>1200 && windowklein==true){

                                    nextEl.style.display = 'none';
                                    windowklein = false;
                                    $(".dropdown-menu").removeClass("show");

                                }

                            });

                        }
                    }

                }
            });

        })

    }

    fenstersizecheck();



});
