// Build something cool. <3
import PureCounter from "@srexi/purecounterjs";

const pure = new PureCounter();
import '../sass/app.scss';


import 'lazysizes';

// import '~bootstrap'
import * as bootstrap from 'bootstrap';

import '../scripts/navbar.js';
import * as Popper from '@popperjs/core';
import $ from 'jquery';
import 'laravel-datatables-vite';
import '~bootstrap-table';

import Chart from 'chart.js/auto';
window.Chart = Chart;
window.$ = $;
window.Popper = Popper;
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
